import queryString from 'query-string';
import { ChainRun, ChainRunTrace, Message, PaginatedCursorQuery, PaginatedResult, MessageContextData } from '../types';
import AuthAPI from './auth';
import { BaseAPI } from './base-api';

export default class MessagesAPI extends BaseAPI {
    private path: string = 'messages';
    constructor(
        baseUrl: string,
        auth: AuthAPI,
    ) {
        super(baseUrl, auth);
    }

    async create(body: string, threadId?: string, contextData: MessageContextData = {}): Promise<Message> {
        try {
            const response = await this.post<Message>(`/${this.path}`, { body, threadId, contextData });
            return response;
        } catch (error) {
            throw this.handleError(error);
        }
    }
    async getAllMessageChainRun(
        messageId: string,
        query: {
            search?: string,
            ids?: string[],
        } & PaginatedCursorQuery = {}
    ): Promise<PaginatedResult<ChainRun>> {
        try {
            const queryStr = queryString.stringify(query);
            const response = await this.get<PaginatedResult<ChainRun>>(`/${this.path}/${messageId}/chain-runs?${queryStr}`);
            return response;
        } catch (error) {
            throw this.handleError(error);
        }
    }
    async getAllMessageChainRunTrace(
        messageId: string,
        query: {
            search?: string,
            ids?: string[],
        } & PaginatedCursorQuery = {}
    ): Promise<PaginatedResult<ChainRunTrace>> {
        try {
            const queryStr = queryString.stringify(query);
            const response = await this.get<PaginatedResult<ChainRunTrace>>(`/${this.path}/${messageId}/chain-run-traces?${queryStr}`);
            return response;
        } catch (error) {
            throw this.handleError(error);
        }
    }
}
