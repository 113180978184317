import _ from 'lodash';
import { useEffect, useState } from 'react';
import Client from '../lib/client';
import { Message, Thread } from '../shared';


export default function useThreadMessages(threadId?: string) {
    const [data, setData] = useState<Message[]>([]);

    const client = new Client();
    const refetch = (threadId?: string, callback?: (data: Message[]) => void) => {
        if (!threadId) {
            return [];
        }
        client.api.threads.getAllThreadMessages(threadId, { last: 50 })
            .then((result) => {
                // const messages = _.reverse(result.data);
                const messages = result.data;
                setData(messages);
                callback && callback(messages);
            })
            .catch((error: Error) => console.error('error fetching messages data for hook', error));
    };

    useEffect(() => {
        refetch(threadId);
    }, [threadId]);


    return { data, refetch };
}
