import * as AppPages from './pages';
import { CustomRouteSpec } from './lib/components/route';

const routes: CustomRouteSpec[] = [
    { path: '/site', pageName: 'website', type: 'public', component: AppPages.Website.Index },

    { path: '/', pageName: 'home', type: 'private', component: AppPages.Home.HomeIndex },

    { path: '/chat', pageName: 'chat', type: 'private', component: AppPages.Chat.Index },    

    { path: '/account/settings', pageName: 'account-settings', type: 'private', component: AppPages.Account.AccountSettings },
    { path: '/account/usage', pageName: 'account-usage', type: 'private', component: AppPages.Account.AccountUsage },
    { path: '/account/members', pageName: 'account-members', type: 'private', component: AppPages.Account.AccountMembers },
    { path: '/account/billing', pageName: 'account-billing', type: 'private', component: AppPages.Account.AccountBilling },

    { path: '/sign-in', pageName: 'sing-in', type: 'non-private', component: AppPages.Auth.SignIn },
    { path: '/sign-up', pageName: 'sing-up', type: 'non-private', component: AppPages.Auth.SignUp },
    { path: '/verification-code', pageName: 'verification-code', type: 'non-private', component: AppPages.Auth.VerificationCode },

    { path: '/admin/account/usage', pageName: 'admin-account-usage', type: 'private', component: AppPages.Admin.Account.AccountUsage },
    { path: '/admin/account/billing', pageName: 'admin-account-usage', type: 'private', component: AppPages.Admin.Account.AccountBilling },

    { path: '*', pageName: 'not-found', type: 'public', component: AppPages.Errors.Error404 },
];

export default routes;
