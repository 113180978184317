const config = {
    pollingTime: 2000,
    client: {
        url: 'https://ElysiaAI.com',
    },
    api: {
        url: 'https://api.ElysiaAI.com',
    },
    ws: {
        url: 'wss://ws.ElysiaAI.com',
    },
};

export default config;
