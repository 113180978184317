import { useLocation, Navigate, useParams } from 'react-router-dom';
import * as queryString from 'query-string';
import _ from 'lodash';
import { User } from '../../shared';

export type CustomRouteSpec = {
    path: string,
    pageName: string,
    component: CustomRouteComponent,
    type: CustomRouteType,
};
export type CustomRouteType = 'private' | 'public' | 'non-private';
export type CustomRouteProps = {
    type: 'private' | 'public' | 'non-private',
    pageName: string,
    user?: User,
    urlValues?: queryString.ParsedQuery<string>,
};
export type CustomPrivateRouteProps = {
    type: 'private',
    pageName: string,
    user: User,
    urlValues?: queryString.ParsedQuery<string>,
};
export type CustomRouteComponent =
    React.FunctionComponent<CustomRouteProps & any> |
    React.ComponentClass<CustomRouteProps & any>;

export function CustomRoute(props: CustomRouteProps & { Component: CustomRouteComponent }) {
    const location = useLocation();
    let params = useParams();
    const urlValues = queryString.parse(location.search);

    switch (props.type) {
        case 'public':
            return <props.Component {...props} {...params} urlValues={urlValues} />;
        case 'private':
            if (!props.user) {

                const redirectUrl = encodeURIComponent(`${_.get(location, 'pathname', '/')}${_.get(location, 'search', '')}`);
                return <Navigate to={`/sign-in?redirectUrl=${redirectUrl}`} />;
            }

            return <props.Component {...props} {...params} urlValues={urlValues} />;

        case 'non-private':
            if (!props.user) {
                return <props.Component {...props} {...params} urlValues={urlValues} />;
            }

            if (urlValues?.redirectUrl) {
                const redirectUrl = decodeURIComponent(urlValues?.redirectUrl.toString());
                return <Navigate to={redirectUrl} />;
            }

            return <Navigate to={'/'} />;

        default:
            return <props.Component {...props} {...params} urlValues={urlValues} />;
    }
}
