import _ from 'lodash';
import { useEffect, useState } from 'react';
import Client from '../lib/client';
import { AccountMonthlyReport } from '../shared';

export default function useAccountUsage(accountId?: string) {
    const [data, setData] = useState<AccountMonthlyReport[]>();

    const client = new Client();
    const refetch = (accountId?: string, callback?: (data: AccountMonthlyReport[]) => void) => {
        if (!accountId) {
            return [];
        }
        client.api.accounts.getUsage(accountId)
            .then((result) => {
                setData(result);
                callback && callback(result);
            })
            .catch((error: Error) => console.error('error fetching account usage data for hook', error));
    };

    useEffect(() => {
        refetch(accountId);
    }, [accountId]);

    return { data, refetch };
}
