import axios from 'axios';
import _ from 'lodash';
import AuthAPI from './auth';

export interface AuthState {
    userId: string;
    accountId: string;
    authToken: string;
    refreshToken: string;
    expiresAt: number;
}

export interface IClientAuthStateHandler {
    getAuthState(): AuthState | null;
    setAuthState(authState: AuthState): void;
    unsetAuthState(): void;
    isAuthStateExpired(authState: AuthState): boolean;
}

export interface APIError extends Error {
    status?: number;
    statusText?: string;
}

export class BaseAPI {
    constructor(
        private baseUrl: string,
        private auth?: AuthAPI,
    ) {}

    public async post<T>(url: string, payload: any): Promise<T> {
        // Send a POST request
        const instance = await this.getInstance();
        try {
            const response = await instance({
                url,
                method: 'post',
                data: payload,
            });
            return response.data as T;
        } catch (err) {
            throw this.handleError(err);
        }
    }

    public async put<T>(url: string, payload: any): Promise<T> {
        // Send a PUT request
        const instance = await this.getInstance();
        try {
            const response = await instance({
                url,
                method: 'put',
                data: payload,
            });
            return response.data as T;
        } catch (err) {
            throw this.handleError(err);
        }
    }

    public async delete<T>(url: string, payload: any): Promise<T> {
        // Send a DELETE request
        const instance = await this.getInstance();
        try {
            const response = await instance({
                url,
                method: 'delete',
                data: payload,
            });
            return response.data as T;
        } catch (err) {
            throw this.handleError(err);
        }
    }

    public async get<T>(url: string): Promise<T> {
        // Send a POST request
        const instance = await this.getInstance();
        try {
            const response = await instance({
                url,
                method: 'get',
            });
            return response.data as T;
        } catch (err) {
            throw this.handleError(err);
        }
    }

    public handleError(error: unknown): Error {
        // error
        const apiError: APIError = new Error();
        apiError.status = _.get(error, 'status', 500);
        apiError.statusText = _.get(error, 'statusText', 'Internal Server Error');
        apiError.message = _.get(error, 'statusText', 'API ERROR');

        if (_.has(error, 'response')) {
            apiError.message = _.get(error, 'response.data', 'API ERROR');
            apiError.status = _.get(error, 'response.status', 500);
            apiError.statusText = _.get(error, 'response.statusText', 'Internal Server Error');
            return apiError;
        }

        if (_.has(error, 'request')) {
            apiError.message = 'The request was made but no response was received';
            return apiError;
        }

        if (_.has(error, 'message')) {
            apiError.message = _.get(error, 'message', '');
            return apiError;
        }

        return apiError;
    }

    private async getInstance() {
        let headers = {
        };

        if (this.auth) {
            const auth = await this.auth.getAuthState(true);
            if (auth) {
                headers = {
                    ...headers,
                    authorization: `Bearer ${auth.authToken}`,
                };
            }
        }

        const instance = axios.create({
            headers,
            baseURL: this.baseUrl,
        });
        return instance;
    }
}
