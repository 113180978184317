import _ from 'lodash';
import { useEffect, useState } from 'react';
import Client from './client';
import { AccountBilling, PaginatedResult } from '../shared';

export default function useAccountBillings(accountId?: string) {
    const [data, setData] = useState<PaginatedResult<AccountBilling>>();

    const client = new Client();
    const refetch = (accountId?: string, callback?: (data: PaginatedResult<AccountBilling>) => void) => {
        if (!accountId) {
            return [];
        }
        client.api.accounts.getAllAccountBillings(accountId, { last: 50 })
            .then((result) => {
                // const messages = _.reverse(result.data);
                // const runs = _.sortBy(result.data, ['startedAt']);
                setData(result);
                callback && callback(result);
            })
            .catch((error: Error) => console.error('error fetching account billings runs data for hook', error));
    };

    useEffect(() => {
        refetch(accountId);
    }, [accountId]);

    return { data, refetch };
}
