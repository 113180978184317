import { AuthState, IClientAuthStateHandler } from '../../../shared/api/base-api';

const LOCAL_STORAGE_AUTH_STATE = '@ELYSIA:AUTH_STATE';

const getAuthState = (): AuthState | null => {
    const authStateRaw = localStorage.getItem(LOCAL_STORAGE_AUTH_STATE);

    if (!authStateRaw) {
        return null;
    }

    let authState: AuthState;
    try {
        authState = JSON.parse(authStateRaw);
    } catch (error) {
        return null;
    }

    if (!isAuthStateValid(authState)) {
        unsetAuthState();
        return null;
    }

    return authState;
};

const setAuthState = (authState: AuthState): void => {
    if(!isAuthStateValid(authState)) {
        throw new Error('Invalid auth state');
    }

    unsetAuthState();
    localStorage.setItem(LOCAL_STORAGE_AUTH_STATE, JSON.stringify(authState));
};

const unsetAuthState = (): void => {
    localStorage.removeItem(LOCAL_STORAGE_AUTH_STATE);
};

const isAuthStateExpired = (authState: AuthState): boolean => {
    // if there is no defined expiration date, get a new one
    if (!authState || !authState.expiresAt)
        return true;

    // if the token expired, get a new one
    if (Date.now() >= authState.expiresAt)
        return true;

    return false;
};

const isAuthStateValid = (obj: any): obj is AuthState => {
    if (
        typeof obj !== 'object'
        || obj === null
        || !obj.authToken
        || typeof obj.authToken !== 'string'
        || !obj.expiresAt
        || typeof obj.expiresAt !== 'number'
        || !obj.refreshToken
        || typeof obj.refreshToken !== 'string'
        || !obj.userId
        || typeof obj.userId !== 'string'
    ) {
        return false;
    }

    return true;
};

const clientAuthStateHandler: IClientAuthStateHandler = {
    getAuthState,
    setAuthState,
    unsetAuthState,
    isAuthStateExpired
};

export default clientAuthStateHandler;
