import queryString from 'query-string';
import { Message, PaginatedCursorQuery, PaginatedResult, InboundMessage } from '../types';
import AuthAPI from './auth';
import { BaseAPI } from './base-api';

export default class InboundMessagesAPI extends BaseAPI {
    private path: string = 'inbound-messages';
    constructor(
        baseUrl: string,
        auth: AuthAPI,
    ) {
        super(baseUrl, auth);
    }

    async getAll(
        query: {
            ids?: string[],
        } & PaginatedCursorQuery = {}
    ): Promise<PaginatedResult<InboundMessage>> {
        try {
            const queryStr = queryString.stringify(query);
            const response = await this.get<PaginatedResult<InboundMessage>>(`/${this.path}?${queryStr}`);
            return response;
        } catch (error) {
            throw this.handleError(error);
        }
    }

    async acknowledgeById(id: string): Promise<InboundMessage> {
        try {
            const response = await this.put<InboundMessage>(`/${this.path}/${id}/acknowledge`, {});
            return response;
        } catch (error) {
            throw this.handleError(error);
        }
    }
}
