import React, { useState } from 'react';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import Client from '../../lib/client';
import { User } from '../../shared';
import './page.css';

const DEFAULT_SIDEBAR_WIDTH = 250;
const COLLAPSED_SIDEBAR_WIDTH = 0; // Adjust this as needed

const signOut = async () => {
    const client = new Client();
    await client.api.auth.signOut();
}

type PageProps = {
    user?: User;
    leftBar?: React.ReactNode;
    rightBar?: {
        content: React.ReactNode,
        width?: number,
        collapsible?: boolean, // Added collapsible property
        toggleBtnIcon?: React.ReactNode,
    };
    pageName: string;
    children?: React.ReactNode;
};

export function Page(props: PageProps) {
    const [rightBarOpen, setRightBarOpen] = useState(true); // State to manage rightBar open/close

    const toggleRightBar = () => {
        if (props.rightBar?.collapsible) {
            setRightBarOpen(!rightBarOpen);
        }
    };

    const getRightBarWidth = () => {
        return rightBarOpen ? (props.rightBar?.width || DEFAULT_SIDEBAR_WIDTH) : COLLAPSED_SIDEBAR_WIDTH;
    };

    return (
        <>
            <div className="container-fluid">
                <div id="page-root-container" className="d-flex">
                    {props.leftBar && (
                        <div id="page-sidebar-menu" className="">
                            {props.leftBar}
                        </div>
                    )}

                    <div id="page-main-container" className={`d-flex flex-fill ${rightBarOpen && 'right-bar-open'}`}>
                        {props.children}
                    </div>

                    {props.rightBar && (
                        <>
                            <button onClick={toggleRightBar} className="btn btn-light btn-sm border toggle-button">
                                {props.rightBar.toggleBtnIcon && props.rightBar.toggleBtnIcon}
                                {rightBarOpen ? (<i className="bi bi-arrow-bar-right"></i>) : (<i className="bi bi-arrow-bar-left"></i>)}
                            </button>
                            <div
                                id="page-sidebar-menu"
                                className={`right-bar ${rightBarOpen ? 'right-bar-open' : 'right-bar-closed'}`}
                                style={{
                                    width: getRightBarWidth(),
                                    maxWidth: getRightBarWidth(),
                                    minWidth: getRightBarWidth()
                                }}
                            >
                                {props.rightBar.content}
                            </div>
                        </>
                    )}
                </div>
            </div>
        </>
    );
}

export function useWithPage(pageName: string, Component: React.FunctionComponent<any> | React.ComponentClass<any, any>) {
    return function (props: PageProps) {
        <Page
            {...props}
            pageName={pageName}
        >
            <Component {...props}></Component>
        </Page>
    }
}
