import _ from 'lodash';
import { Page } from '../../../components/layout/page';
import { SideBar } from '../../../components/sidebar';
import { Chat } from '../../../components/chat';
import { CustomRouteProps } from '../../../lib/components/route';
import ChatSidebarBottom from '../../chat/components/sidebar-bottom';
import SidebarMenu from '../../../components/sidebar-menu';

export default function HomeIndex(props: CustomRouteProps) {
    return (
        <Page
            {...props}
            leftBar={(
                <SideBar
                    {...props}
                    bdColor='light'
                    bottomContainer={<ChatSidebarBottom user={props.user} />}
                    topContainer={null}
                >
                    <SidebarMenu {...props} />
                </SideBar>
            )}
            // rightBar={{
            //     content: (
            //         <SideBar
            //             {...props}
            //             bdColor='light'
            //         >
            //             <Chat
            //                 clearContext={clearContext}
            //                 context={chatContextData}
            //                 // autoSubmit={autoSubmitPrompt}
            //             />
            //         </SideBar>
            //     ),
            //     width: 500,
            // }}
            pageName={props.pageName}
        >

            <div className="container-fluid">
                <div className="row h-100">
                    <div className="col h-100 d-flex p-0">
                        <Chat />
                    </div>
                </div>
            </div>

        </Page>
    );
}
