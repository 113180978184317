import _ from 'lodash';
import { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { atomDark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import SyntaxHighlighter from 'react-syntax-highlighter/dist/esm/default-highlight';
import TextareaAutosize from 'react-textarea-autosize';

import { Message, MessageRole, Thread } from '../../shared';
import useThreads from '../../lib/use-threads';
import { useWSMessage } from '../../lib/use-ws-message';
import Client from '../../lib/client';
import useThreadMessages from '../../lib/use-thread-messages';
import { getDateRelativeStr } from '../../lib/utils/date';

import './chat.css';

const scrollToTop = () => {
    const div = window.document.getElementById('thread-messages-container');
    if (div) {
        setTimeout(() => {
            div.scrollTop = div.scrollHeight;
        }, 1);
    }
};

function ThreadRow(props: { thread: Thread, onClickThread?: (thread: Thread) => void }) {
    return (
        <div
            key={props.thread.id}
            className="bg-light border border-ligth rounded shadow-sm p-2 mx-2 mb-2"
            onClick={() => props.onClickThread && props.onClickThread(props.thread)}
        >
            <p className="text-truncate mb-0"><i className="bi bi-chat me-3" />{props.thread.description}</p>
            <p className="text-truncate mb-0">
                <small className="text-muted me-2">{props.thread.lastMessageCreatedAt && getDateRelativeStr(props.thread.lastMessageCreatedAt)}</small>
                <small className="text-muted me-2">{props.thread.messageCount} messages</small>
            </p>
        </div>
    );
}
function MessageRow(props: { message: Message }) {
    let getRoleClass = (role: MessageRole) => {
        switch (role) {
            case MessageRole.USER:
                return 'text-muted';
            case MessageRole.ASSISTANT:
                return '';
            case MessageRole.SYSTEM:
                return 'fw-bold';
            default:
                return '';
        }
    };
    let getRoleIcon = (role: MessageRole) => {
        switch (role) {
            case MessageRole.USER:
                return <i className="bi bi-person-circle"></i>;
            case MessageRole.ASSISTANT:
                return <i className="bi bi-robot"></i>;
            case MessageRole.SYSTEM:
                return <i className="bi bi-gear"></i>;
            default:
                return '';
        }
    };

    return (
        <div key={props.message.id} className={`row`}>
            <div className="col">
                <div className={`p-2 rounded mx-2 mb-4 d-flex flex-row`}>
                    <div className="me-2">
                        {getRoleIcon(props.message.role)}
                    </div>
                    <div>
                        <div>
                            <p className="mb-0 fw-bold">
                                {_.capitalize(props.message.role)}
                                <small className="fw-normal text-muted ms-2">{getDateRelativeStr(props.message.createdAt)}</small>
                            </p>
                        </div>
                        <div>
                            <ReactMarkdown
                                className={`message-md-body-container ${getRoleClass(props.message.role)}`}
                                children={props.message.body}
                                remarkPlugins={[remarkGfm]}
                                components={{
                                    code({ node, inline, className, children, ...props }) {
                                        const match = /language-(\w+)/.exec(className || '')
                                        return !inline && match ? (
                                            <SyntaxHighlighter
                                                {...props}
                                                children={String(children).replace(/\n$/, '')}
                                                style={atomDark}
                                                language={match[1]}
                                                PreTag="div"
                                            />
                                        ) : (
                                            <code {...props} className={className}>
                                                {children}
                                            </code>
                                        )
                                    }
                                }}
                            />
                            <div className="message-context-container">
                                <p className="mb-0">
                                    {/* {JSON.stringify(props.message?.contextData || {})} */}
                                </p>
                            </div>
                            <div className="message-card-container">
                                <p className="mb-0">
                                    {/* {JSON.stringify(props.message?.cardData || {})} */}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

function ChatThreadList(props: {
    onCreateThread: (description?: string) => Promise<void>,
    threads: Thread[],
    selectedThread: Thread | null,
    onClickThread?: (thread: Thread) => void,
    onClose?: () => void | Promise<void>,
}) {
    let threads = props.threads;

    if (props.selectedThread && !threads.find((thread) => props.selectedThread && thread.id === props.selectedThread.id)) {
        threads = [props.selectedThread, ...threads];
    }

    return (
        <div className="d-flex flex-column flex-fill border-start w-100 overflow-hidden">
            <div className="d-flex flex-column p-2 border-ligth border-bottom shadow">
                <p className="h6 text-truncate text-capitalize mt-2"><i className="bi bi-robot me-2"></i>Ask ElysiaAI</p>
                <div className="d-flex justify-content-end">
                    {props.onClose && <button className="btn btn-sm btn-secondary ms-2" onClick={() => props.onClose && props.onClose()}>Close</button>}
                    <button className="btn btn-sm btn-primary ms-2" onClick={() => props.onCreateThread('New Thread')}>New Thread</button>
                </div>
            </div>

            <div className="d-flex flex-column d-flex flex-fill bg-white w-100 overflow-hidden overflow-y-auto pt-2">
                {threads.map((thread) => (
                    <ThreadRow onClickThread={props.onClickThread} thread={thread} />
                ))}
            </div>
        </div>
    );
}
export function ChatThread(props: {
    initialPrompt?: string,
    thread: Thread | null,
    readOnly?: boolean,
    reverse?: boolean,
    onClickBack?: () => void,
    onCreateThread?: (description?: string) => Promise<void>,
    onCreateMessage?: () => Promise<void>,
    onClose?: () => void | Promise<void>
}) {
    const [body, setBody] = useState<string>(props.initialPrompt || '');
    const [sentMessage, setSentMessage] = useState<Message | undefined>();
    const { data: messages, refetch } = useThreadMessages(props.thread?.id);

    useWSMessage('thread.updated', (data) => {
        if (props.thread && data.payload.thread && data.payload.thread.id === props.thread.id) {
            setSentMessage(undefined);
            refetch(props.thread?.id);
        }
    }, [props.thread]);

    const onCreateMessage = async (body: string, threadId?: string) => {
        // make call to api...
        const client = new Client();
        const message = await client.api.messages.create(body, threadId);
        setBody('');
        setSentMessage(message);
        scrollToTop();
        props.onCreateMessage && props.onCreateMessage();
    };

    const onKeyUp = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
        if (event.code === 'Enter' && event.shiftKey) {
            // this is weird, but kinda works...
            event.preventDefault();
            setBody(body + '\n');
            return;
        }
        if (event.code === 'Enter') {
            event.preventDefault();
            onCreateMessage(body, props.thread?.id);
        }
    }

    return (
        <div className="d-flex flex-column flex-fill border-start w-100 overflow-hidden">
            <div className="d-flex flex-column p-2 border-ligth border-bottom shadow-sm">
                <p className="h6 text-truncate text-capitalize mt-2">{props.thread?.description || 'New Thread'}</p>
                <div className="d-flex justify-content-end">
                    {props.onClose && <button className="btn btn-sm btn-secondary btn-small ms-2" onClick={() => props.onClose && props.onClose()}>Close</button>}
                    {props.onClickBack && <button className="btn btn-sm btn-secondary btn-small ms-2" onClick={() => props.onClickBack && props.onClickBack()}>Back</button>}
                    {props.onCreateThread && <button className="btn btn-sm btn-primary btn-small ms-2" onClick={() => props.onCreateThread && props.onCreateThread('New Thread')}>New Thread</button>}
                </div>
            </div>
            <div id="thread-messages-container" className={`d-flex ${props.reverse ? 'flex-column' : 'flex-column-reverse'} d-flex flex-fill bg-white w-100 overflow-hidden overflow-y-auto pt-2`}>
                {(sentMessage ? [...messages, sentMessage] : messages).map((message) => <MessageRow message={message}></MessageRow>)}
            </div>
            {!props.readOnly && (
                <div className="border-top p-2">
                    <div className="border bg-white rounded p-2 align-items-end d-flex flex-column">
                        <TextareaAutosize
                            id="new-message-input"
                            maxRows={20}
                            minRows={1}
                            autoFocus
                            autoComplete="off"
                            value={body}
                            onKeyUp={onKeyUp}
                            onChange={(event) => setBody(event.target.value)}
                            className="new-message-textarea form-control border-0 p-0"
                            placeholder="Send a message..."
                            aria-label="Send a message..."
                            aria-describedby="button-send-message"
                        />
                        <button onClick={() => onCreateMessage(body, props.thread?.id)} disabled={body === ''} className="btn btn-success mt-2" type="button" id="button-send-message">Send</button>
                    </div>
                </div>
            )}
        </div>
    );
}

export function Chat(props: {
    readOnly?: boolean;
}) {
    const [initialPrompt, setInitialPrompt] = useState('');
    const [thread, setThread] = useState<Thread | null>(null);
    const { data: threads, refetch } = useThreads();

    useEffect(() => {
        if (!thread) {
            onCreateThread();
        }
    }, []);

    useWSMessage('thread.updated', (data) => {
        if (thread && data?.payload?.thread?.id === thread.id) {
            setThread(data.payload.thread);
        }
        refetch();
    });

    const onClickBack = () => {
        setThread(null);
    };

    const onCreateThread = async (description?: string) => {
        // make call to api...
        const client = new Client();
        const thread = await client.api.threads.create('New Thread');
        setThread(thread);
    };

    const onCreateMessage = async () => {
        setInitialPrompt('');
    };

    if (thread) {
        return (
            <ChatThread
                // onClose={props.onClose}
                initialPrompt={initialPrompt}
                onClickBack={onClickBack}
                onCreateThread={onCreateThread}
                onCreateMessage={onCreateMessage}
                readOnly={props.readOnly}
                thread={thread} />
        );
    }

    return (
        <ChatThreadList
            // onClose={props.onClose}
            selectedThread={thread}
            threads={threads}
            onClickThread={setThread}
            onCreateThread={onCreateThread} />
    );
}
