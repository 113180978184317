import React from 'react';
import _ from 'lodash';
import { Link, Navigate } from 'react-router-dom';
import * as queryString from 'query-string';

import Client from '../../../lib/client';
import { CustomRouteProps } from '../../../lib/components/route';
import '../css/sign-in.css';

type State = {
  loading: boolean;
  username: string;
  publicCode?: string;
  message?: {
    type: 'success' | 'danger';
    text: string;
  }
}

export default class SignIn extends React.Component<CustomRouteProps, State> {
  constructor(props: CustomRouteProps) {
    super(props);
    this.state = {
      loading: false,
      username: '',
    };
  }

  onChangeText = (field: 'username', event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    event.preventDefault();
    this.setState({ username: event.target.value });
  }

  onSubmit = async (event: React.ChangeEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (this.validateForm()) {
      this.setState({ loading: true });

      try {
        const client = new Client();
        const publicCode = await client.api.auth.requestVerificationCode(
          this.state.username,
        );

        this.setState({
          publicCode,
          loading: false,
          message: undefined
        });
      } catch (error) {
        this.setState({
          loading: false,
          publicCode: undefined,
          message: {
            type: 'danger',
            text: _.get(error, 'message', 'Unable to sign in. Please try again!'),
          }
        });
      }
    }
  }

  validateForm() {
    return true;
  }

  render() {
    if (this.state.publicCode) {
      const urlValues = {
        ...this.props.urlValues,
        username: this.state.username,
        publicCode: this.state.publicCode,
      };
      const redirectUrl = queryString.stringifyUrl({ url: '/verification-code', query: urlValues });
      return <Navigate to={redirectUrl}/>
    }

    return (
      <div className="auth-sign-in-view">
        <main className="mt-5 form-signin">
          <form autoComplete="off" onSubmit={this.onSubmit}>

            <div className="row mb-3">
              <div className="col">
                <p className="h3 mb-0 fw-normal">Sign In</p>
                <p className="text-muted mb-3">Passwords are a pain. Get a code sent to your email to sign in!</p>
              </div>
            </div>

            <div className="row">
              <div className="col">
                {
                  this.state.message ? (
                    <div className={`alert alert-${this.state.message.type}`} role="alert">
                      {this.state.message.text}
                    </div>
                  ) : null
                }
              </div>
            </div>

            <div className="row mb-4">
              <div className="col">
                <label className="fw-bold">Username</label>
                <input
                  onChange={this.onChangeText.bind(this, 'username')}
                  value={this.state.username}
                  type="text"
                  autoCapitalize="off"
                  className="form-control"
                  required={true}
                  autoFocus={true}
                  autoComplete="off"
                />
              </div>
            </div>

            <div className="row">
              <div className="col">
                <button className="w-100 btn btn-primary" type="submit">Sign in</button>
              </div>
            </div>

            <hr/>

            <div className="row">
              <div className="col">
                <p className="mb-0"><small>Or, if you dont have an account, <Link className="text-decoration-none" to={`/sign-up?${queryString.stringify(this.props.urlValues || {})}`}>Register!</Link></small></p>
              </div>
            </div>
          </form>
        </main>
      </div>
    );
  }
}
