import _ from 'lodash';
import { useEffect, useState } from 'react';
import Client from '../lib/client';
import { Thread } from '../shared';


export default function useThread(threadId?: string) {
    const [data, setData] = useState<Thread>();

    let refetch: Function;
    if (!threadId) {
        refetch = _.noop;
    } else {
        const client = new Client();
        refetch = () => client.api.threads.getAll({ ids: [threadId] })
            .then((result) => {
                setData(result.data[0]);
            })
            .catch((error: Error) => console.error('error fetching threads data for hook', error));
    }

    useEffect(() => {
        refetch();
    }, [threadId]);


    return { data, refetch };
}
