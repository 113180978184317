import { Link } from 'react-router-dom';

function Error404() {
    return (
      <div className="page-404">
        <div className="row">
          <div className="col text-center py-5 my-5">
            <p className="h1 display-1">404</p>
            <p className="h2"><strong>Page not found.</strong></p>
            <h3 className="h2 mb-5">Sorry! Looks like you are lost.</h3>
            <div>
              <Link className="btn btn-primary rounded shadow btn-wd mr-1 ml-1" to="/">Home</Link>
            </div>
          </div>
        </div>
      </div>
    );
}

export default Error404;
