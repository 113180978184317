import _ from 'lodash';
import { Page } from '../../../components/layout/page';
import { SideBar } from '../../../components/sidebar';
import { Chat, ChatThread } from '../../../components/chat';
import { CustomRouteProps } from '../../../lib/components/route';
import ChatSidebarBottom from '../../chat/components/sidebar-bottom';
import SidebarMenu from '../../../components/sidebar-menu';
import useAccountConfig from '../../../lib/use-account-config';
import useThread from '../../../lib/use-thread';

export default function HomeIndex(props: CustomRouteProps) {
    const accountConfig = useAccountConfig(props.user?.accountId);
    const { data: mainThread, refetch } = useThread(accountConfig?.threadId);

    return (
        <Page
            {...props}
            leftBar={(
                <SideBar
                    {...props}
                    bdColor='light'
                    bottomContainer={<ChatSidebarBottom user={props.user} />}
                    topContainer={null}
                >
                    <SidebarMenu {...props} />
                </SideBar>
            )}
            rightBar={{
                content: (
                    <SideBar
                        {...props}
                        bdColor='white'
                    >
                        <Chat />
                        
                    </SideBar>
                ),
                width: 500,
                collapsible: true,
                toggleBtnIcon: <i className="bi bi-robot me-2"/>
            }}
            pageName={props.pageName}
        >

            <div className="container-fluid">
                <div className="row h-100">
                    <div className="col h-100 d-flex p-0">
                        {mainThread && <ChatThread readOnly={true} reverse={true} thread={mainThread} />}

                        {/* <Chat /> */}
                    </div>
                </div>
            </div>

        </Page>
    );
}
