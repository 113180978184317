import React from 'react';
import _ from 'lodash';
import { Link, Navigate } from 'react-router-dom';
import * as queryString from 'query-string';
import Client from '../../../lib/client';
import { CustomRouteProps } from '../../../lib/components/route';
import '../css/sign-in.css';

type State = {
  loading: boolean;
  username: string;
  email: string;
  name: string;
  publicCode?: string;
  message?: {
    type: 'success' | 'danger';
    text: string;
  }
}

export default class SignIn extends React.Component<CustomRouteProps, State> {
  constructor(props: CustomRouteProps) {
    super(props);

    this.state = {
      loading: false,
      username: _.get(props.urlValues, 'username')?.toString() || '',
      email: _.get(props.urlValues, 'email')?.toString() || '',
      name: _.get(props.urlValues, 'name')?.toString() || '',
    };
  }

  onChangeText = (field: 'email' | 'name' | 'username', event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    event.preventDefault();
    
    if (field === 'username') {
      this.setState({ username: event.target.value });
      return;
    }

    if (field === 'name') {
      this.setState({ name: event.target.value });
      return;
    }

    this.setState({ email: event.target.value });
  }

  onSubmit = async (event: React.ChangeEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (this.validateForm()) {
      this.setState({ loading: true });

      try {
        const client = new Client();
        await client.api.users.register(
          this.state.username,
          this.state.email,
          this.state.name,
        );
        const publicCode = await client.api.auth.requestVerificationCode(
          this.state.username,
        );
        this.setState({
          publicCode,
          loading: false,
          message: undefined
        });
      } catch (error) {
        this.setState({
          loading: false,
          publicCode: undefined,
          message: {
            type: 'danger',
            text: _.get(error, 'message', 'Unable to sign in. Please try again!'),
          }
        });
      }
    }
  }

  validateForm() {
    return true;
  }

  render() {
    if (this.state.publicCode) {
      const urlValues = {
        ...this.props.urlValues,
        username: this.state.username,
        publicCode: this.state.publicCode,
      };
      const redirectUrl = queryString.stringifyUrl({ url: '/verification-code', query: urlValues });
      return <Navigate to={redirectUrl}/>
    }

    return (
      <div className="auth-sign-in-view">
        <main className="mt-5 form-signin">
          <form autoComplete="off" onSubmit={this.onSubmit}>

          <div className="row mb-3">
              <div className="col">
                <p className="h3 mb-0 fw-normal">Sign Up</p>
                <p className="text-muted mb-3">This will be quick. Just need name and email to create an acount.</p>
              </div>
            </div>

            <div className="row">
              <div className="col">
                {
                  this.state.message ? (
                    <div className={`alert alert-${this.state.message.type}`} role="alert">
                      {this.state.message.text}
                    </div>
                  ) : null
                }
              </div>
            </div>

            <div className="row mb-2">
              <div className="col">
                <label className="fw-bold">Email</label>
                <input
                  onChange={this.onChangeText.bind(this, 'email')}
                  value={this.state.email}
                  type="email"
                  className="form-control"
                  required={true}
                  autoFocus={true}
                  autoComplete="off"
                />
                {/* <p className="mb-0 text-muted"><small>We will send a verification code to your email</small></p> */}
              </div>
            </div>

            <div className="row mb-2">
              <div className="col">
                <label className="fw-bold">Username</label>
                <input
                  onChange={this.onChangeText.bind(this, 'username')}
                  value={this.state.username}
                  type="text"
                  autoCapitalize="off"
                  className="form-control"
                  required={true}
                  autoComplete="off"
                />
                {/* <p className="mb-0 text-muted"><small>We will send a verification code to your email</small></p> */}
              </div>
            </div>

            <div className="row mb-4">
              <div className="col">
                <label className="fw-bold">Name</label>
                <input
                  onChange={this.onChangeText.bind(this, 'name')}
                  value={this.state.name}
                  type="text"
                  autoCapitalize="words"
                  className="form-control"
                  required={true}
                  autoComplete="off"
                />
              </div>
            </div>

            <div className="row">
              <div className="col">
                <button className="w-100 btn btn-primary" type="submit">Register</button>
              </div>
            </div>

            <hr />

            <div className="row">
              <div className="col">
                <p className="mb-0"><small>Or maybe, if you have an account <Link className="text-decoration-none" to="/sign-in">Sign In!</Link></small></p>
              </div>
            </div>
          </form>
        </main>
      </div>
    );
  }
}
