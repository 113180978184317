import _ from 'lodash';
import { BaseAPI, AuthState, IClientAuthStateHandler } from './base-api';

type IOnAuthStateChangedCallback = (auth: AuthState | null) => Promise<void>;

export default class AuthAPI extends BaseAPI {
    static onAuthStateChangedCallback: { [key: string]: IOnAuthStateChangedCallback } = {};
    private path = 'auth';
    constructor(
        baseUrl: string,
        public clientAuthStateHandler: IClientAuthStateHandler,
    ) {
        super(baseUrl);
    }

    async requestVerificationCode(username: string): Promise<string> {
        try {
            const { publicCode } = await this.post<{ publicCode: string }>(
                `/${this.path}/verification-code/step-1`,
                { username },
            );
            return publicCode;
        } catch (error) {
            throw this.handleError(error);
        }
    }

    async authenticateWithVerificationCode(
        publicCode: string,
        secretCode: string,
    ): Promise<AuthState> {
        try {
            const authState = await this.post<AuthState>(
                `/${this.path}/verification-code/step-2`,
                { publicCode, secretCode },
            );

            this.clientAuthStateHandler.setAuthState(authState);
            this.broadcastAuthStateChange(authState);
            return authState;
        } catch (error) {
            throw this.handleError(error);
        }
    }

    async refreshToken(userId: string, accountId: string, refreshToken: string): Promise<AuthState> {
        try {
            const authState = await this.post<AuthState>(
                `/${this.path}/refresh-token`,
                { userId, accountId, refreshToken },
            );

            this.clientAuthStateHandler.setAuthState(authState);
            this.broadcastAuthStateChange(authState);
            return authState;
        } catch (error) {
            throw this.handleError(error);
        }
    }

    async getAuthState(refreshIfExpired: boolean = false): Promise<AuthState | null> {
        const state = this.clientAuthStateHandler.getAuthState();

        if (!state) {
            return null;
        }

        if (!this.clientAuthStateHandler.isAuthStateExpired(state)) {
            return state;
        }

        if (!refreshIfExpired) {
            return null;
        }

        await this.refreshToken(state.userId, state.accountId, state.refreshToken);
        return this.getAuthState(false);
    }

    async signOut(): Promise<void> {
        const auth = this.clientAuthStateHandler.getAuthState();
        if (auth) {
            this.clientAuthStateHandler.unsetAuthState();
            this.broadcastAuthStateChange(null);
        }
    }

    onAuthStateChanged(
        callback: (auth: AuthState | null) => Promise<void>,
        refreshIfExpired = false,
    ): string {
        const ref = `${_.random(1e5, 1e6)}${Date.now()}`;
        AuthAPI.onAuthStateChangedCallback[ref] = callback;

        setTimeout(async () => {
            this.getAuthState(refreshIfExpired)
                .then(callback)
                .catch(() => this.signOut());
        }, 0);
        return ref;
    }

    offAuthStateChanged(ref: string) {
        delete AuthAPI.onAuthStateChangedCallback[ref];
    }

    private broadcastAuthStateChange(auth: AuthState | null) {
        Object.values(
            AuthAPI.onAuthStateChangedCallback,
        ).forEach((callback) => {
            callback(auth);
        });
    }
}
