import _ from 'lodash';

import { Page } from '../../../components/layout/page';
import { SideBar } from '../../../components/sidebar';
import { CustomRouteProps } from '../../../lib/components/route';
import useAccountConfig from '../../../lib/use-account-config';
import { AssistantLanguage, User } from '../../../shared';
import SidebarMenu from '../../../components/sidebar-menu';

export default function AccountSettings(props: { user: User } & CustomRouteProps) {
  const accountConfig = useAccountConfig(props.user?.accountId);

  const assistantLanguage = _.capitalize(accountConfig?.assistantLanguage);
  const assistantLanguageOption = assistantLanguage === AssistantLanguage.ENGLISH
    ? AssistantLanguage.ESPAÑOL
    : AssistantLanguage.ENGLISH;

  return (
    <Page
      {...props}
      leftBar={
        <SideBar {...props}>
          <SidebarMenu pageName={props.pageName} />
        </SideBar>
      }
      pageName={props.pageName}
    >
      <div className="container-fluid">
        <section className="mt-3 mb-2">
          <div className="row">
            <div className="col-12 col-md-8">
              <div className="">
                <p className="h4">Account settings</p>
                <p className=""></p>
              </div>
            </div>
          </div>
        </section>
        <div className="row">
          <div className="col-12 col-md-4 mb-5">
            <div className="form-group mb-4">
              <fieldset disabled>
                <label className="form-label fw-bold" htmlFor="account-id">
                  Account Id
                </label>
                <input
                  className="form-control"
                  id="account-id"
                  type="text"
                  placeholder=""
                  value={accountConfig?.accountId}
                  disabled
                />
              </fieldset>
            </div>
            <div className="form-group mb-4">
              <fieldset>
                <label className="form-label fw-bold" htmlFor="assistant-name">
                  Assistant Name
                </label>
                <input
                  className="form-control"
                  id="assistant-name"
                  type="text"
                  value={accountConfig?.assistantName}
                />
              </fieldset>
            </div>
            <div className="form-group mb-4">
              <label htmlFor="language" className="form-label fw-bold">
                Assistant Language
              </label>
              <select className="form-select" id="language">
                <option>{assistantLanguage} (default)</option>
                <option>{assistantLanguageOption}</option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </Page>
  );
}
