import _ from 'lodash';
import { useEffect, useState } from 'react';
import Client from '../lib/client';
import { AdminAccountMonthlyReport } from '../shared';

export default function useAdminAccountUsage() {
    const [data, setData] = useState<AdminAccountMonthlyReport[]>();

    const client = new Client();
    const refetch = (callback?: (data: AdminAccountMonthlyReport[]) => void) => {
        client.api.admin.accounts.getUsage()
            .then((result) => {
                setData(result);
                callback && callback(result);
            })
            .catch((error: Error) => console.error('error fetching account usage data for hook', error));
    };

    useEffect(() => {
        refetch();
    }, []);

    return { data, refetch };
}
