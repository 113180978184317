import './App.css';
import useCurrentUser from './lib/use-current-user';
import AppNav from './AppNav';

function App() {
    const user = useCurrentUser();

    if (user === undefined)
        return <div className="App"><p>Loading...</p></div>;

    if (user === null)
        return <AppNav />;

    return <AppNav user={user} />;

}
export default App;