import React from 'react';
import _ from 'lodash';
import { Link, Navigate } from 'react-router-dom';
import Client from '../../../lib/client';
import { CustomRouteProps } from '../../../lib/components/route';
import '../css/sign-in.css';

type State = {
  loading: boolean;
  username?: string;
  publicCode?: string;
  secretCode: string;
  message?: {
    type: 'success' | 'danger';
    text: string;
  }
}

export default class SignIn extends React.Component<CustomRouteProps, State> {
  constructor(props: CustomRouteProps) {
    super(props);

    this.state = {
      loading: false,
      username: _.get(props.urlValues, 'username')?.toString() || undefined,
      publicCode: _.get(props.urlValues, 'publicCode')?.toString() || undefined,
      secretCode: _.get(props.urlValues, 'secretCode')?.toString() || '',
    };
  }

  onChangeText = (field: 'secretCode', event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    event.preventDefault();
        this.setState({ secretCode: event.target.value });
  }

  onSubmit = async (event: React.ChangeEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (this.validateForm()) {
      this.setState({ loading: true });

      try {
        const client = new Client();
        await client.api.auth.authenticateWithVerificationCode(
          this.state.publicCode || '',
          this.state.secretCode,
        );
      } catch (error) {
        console.log(JSON.stringify(error));
        this.setState({
          loading: false,
          secretCode: '',
          message: {
            type: 'danger',
            text: _.get(error, 'message', 'Unable to sign in. Please try again!'),
          }
        });
      }
    }
  }

  onResendCode = async () => {
    if (this.validateForm()) {
      this.setState({ loading: true });

      try {
        const client = new Client();
        const publicCode = await client.api.auth.requestVerificationCode(
          this.state.username || '',
        );

        this.setState({
          publicCode,
          loading: false,
          message: undefined
        });
      } catch (error) {
        this.setState({
          loading: false,
          publicCode: undefined,
          message: {
            type: 'danger',
            text: _.get(error, 'message', 'Unable to sign in. Please try again!'),
          }
        });
      }
    }
  }

  validateForm() {
    return true;
  }

  render() {
    if (!this.state.publicCode || !this.state.username) {
      return <Navigate to="/" />
    }
    return (
      <div className="auth-sign-in-view">
        <main className="mt-5 form-signin">
          <form autoComplete="off" onSubmit={this.onSubmit}>

            <div className="row mb-3">
              <div className="col">
                <p className="h3 mb-0 fw-normal">Verification Code</p>
                <p className="text-muted mb-3">Check your email and enter the verification code</p>
                {/* <p className="lead text-muted fw-light mb-3">{this.state.publicCode}</p> */}
              </div>
            </div>

            <div className="row">
              <div className="col">
                {
                  this.state.message ? (
                    <div className={`alert alert-${this.state.message.type}`} role="alert">
                      {this.state.message.text}
                    </div>
                  ) : null
                }
              </div>
            </div>

            <div className="row mb-3">
              <div className="col">
                <p className="fw-bold mb-0">Username</p>
                <p className="mb-0">{this.state.username}</p>
              </div>
            </div>

            <div className="row mb-4">
              <div className="col">
                <label className="fw-bold">Verification Code</label>
                <input
                  onChange={this.onChangeText.bind(this, 'secretCode')}
                  value={this.state.secretCode}
                  type="tel"
                  className="form-control"
                  placeholder=""
                  required={true}
                  autoFocus={true}
                  autoComplete="off"
                />
                <p className="mb-0 text-muted"><small>Code will expire in 60s</small></p>
              </div>
            </div>

            <div className="row">
              <div className="col">
                <button type="submit" className="w-100 btn btn-primary">Verify</button>
              </div>
              <div className="col">
                <button type="button" className="w-100 btn btn-outline-secondary" onClick={this.onResendCode}>Resend Code</button>
              </div>
            </div>

            <hr />

            <div className="row">
              <div className="col">
                <p className="mb-0">Or maybe <Link className="text-decoration-none" to="/sign-in">Sign In!</Link></p>
              </div>
            </div>

          </form>
        </main>
      </div>
    );
  }
}
