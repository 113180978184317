import { Page } from '../../../components/layout/page';
import { SideBar } from '../../../components/sidebar';
import { CustomRouteProps } from '../../../lib/components/route';
import SidebarMenu from '../../../components/sidebar-menu';
import useAccountBillings from '../../../lib/use-account-billings';
import { getDateStr } from '../../../lib/utils/date';
import { DateTime } from 'luxon';

export default function AccountBilling(props: CustomRouteProps) {
    const { data: accountBillings, refetch } = useAccountBillings(props.user?.accountId);

    return (
        <Page
            {...props}
            leftBar={(
                <SideBar {...props}>
                    <SidebarMenu pageName={props.pageName} />
                </SideBar>
            )}
            pageName={props.pageName}
        >

            <div className="container-fluid">
                <section className="mt-3 mb-2">
                    <div className="row">
                        <div className="col-12 col-md-8">
                            <div className="">
                                <p className="h4">Account billing</p>
                                <p className="">Showing invoices within the past 12 months.</p>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="mt-3 mb-2">
                    <div className="table-responsive">
                        <table className="table table-hover table-bordered table-sm">
                            <thead>
                                <tr className="text-capitalize">
                                    <th>id</th>
                                    <th>status</th>
                                    <th>amount</th>
                                    <th>period</th>
                                    <th>created</th>
                                </tr>
                            </thead>
                            <tbody>
                                {accountBillings?.data.map((bill, idx) => (
                                    <tr key={bill.id} className="">
                                        <td>{bill.id}</td>
                                        <td>{bill.paid ? 'paid' : 'pending'}</td>
                                        <td>{bill.totalAmount}</td>
                                        <td>{getDateStr(bill.periodStart, undefined, DateTime.DATE_MED)} - {getDateStr(bill.periodEnd, undefined, DateTime.DATE_MED)}</td>
                                        <td>{getDateStr(bill.createdAt, undefined, DateTime.DATE_MED)}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </section>
            </div>

        </Page>
    );
}
