import _ from 'lodash';
import { useEffect, useState } from 'react';
import Client from '../lib/client';
import { User } from '../shared';


export default function useCurrentUser() {
    const [user, setUser] = useState<User | null>();

    useEffect(() => {
        const client = new Client();
        const ref = client.api.auth.onAuthStateChanged(async (auth) => {
            if (auth) {
                console.log('user authenticated', auth.userId);
                client.ws.init();
                client.api.users.getMe()
                    .then(setUser)
                    .catch(() => setUser(null));
            } else {
                console.log('user not authenticated');
                client.ws.close();
                setUser(null);
            }
        }, true);

        return () => client.api.auth.offAuthStateChanged(ref);
    }, []);

    return user;
}
