import AuthAPI from './auth';
import UsersAPI from './users';
import ThreadsAPI from './threads';
import MessagesAPI from './messages';
import AccountsAPI from './accounts';
import { IClientAuthStateHandler } from './base-api';
import InboundMessagesAPI from './inbound-messages';
import AdminAccountsAPI from './admin/accounts';

class API {
    auth: AuthAPI;
    users: UsersAPI;
    threads: ThreadsAPI;
    messages: MessagesAPI;
    accounts: AccountsAPI;
    inboundMessages: InboundMessagesAPI;
    admin: {
        accounts: AdminAccountsAPI;
    };

    constructor(
        baseUrl: string,
        clientAuthStateHandler: IClientAuthStateHandler,
    ) {
        this.auth = new AuthAPI(baseUrl, clientAuthStateHandler);
        this.users = new UsersAPI(baseUrl, this.auth);
        this.threads = new ThreadsAPI(baseUrl, this.auth);
        this.messages = new MessagesAPI(baseUrl, this.auth);
        this.accounts = new AccountsAPI(baseUrl, this.auth);
        this.inboundMessages = new InboundMessagesAPI(baseUrl, this.auth);
        this.admin = {
            accounts: new AdminAccountsAPI(baseUrl, this.auth),
        };
    }
}

export {
    API,
};
