import { Page } from '../../../../components/layout/page';
import { SideBar } from '../../../../components/sidebar';
import { CustomRouteProps } from '../../../../lib/components/route';
import SidebarMenu from '../../../../components/sidebar-menu';
import useAccountBillings from '../../../../lib/use-account-billings';
import { getDateStr } from '../../../../lib/utils/date';
import { DateTime } from 'luxon';
import Client from '../../../../lib/client';
import { useState } from 'react';

type Message = {
    type: 'success' | 'danger';
    text: string;
};

export default function AccountBilling(props: CustomRouteProps) {
    const [message, setMessage] = useState<Message>();
    const onClick = () => {
        new Client().api.admin.accounts.generateBilling()
            .then(() => {
                setMessage({
                    type: 'success',
                    text: 'Billing created successfully!',
                });
            })
            .catch((error) => {
                setMessage({
                    type: 'success',
                    text: `Error creating bills! ${error.message}`,
                });
            });
    };
    return (
        <Page
            {...props}
            leftBar={(
                <SideBar {...props}>
                    <SidebarMenu pageName={props.pageName} />
                </SideBar>
            )}
            pageName={props.pageName}
        >

            <div className="container-fluid">
                <section className="mt-3 mb-2">
                    <div className="row">
                        <div className="col-12 col-md-8">
                            <div className="">
                                <p className="h4">Admin Account billing</p>
                                {/* <p className="">Showing invoices within the past 12 months.</p> */}
                            </div>
                        </div>
                    </div>
                </section>
                <section className="mt-3 mb-2">
                    <button onClick={onClick} className='btn btn-primary'>Generate Bills</button>
                    {
                        message ? (
                            <div className={`alert alert-${message.type}`} role="alert">
                                {message.text}
                            </div>
                        ) : null
                    }
                </section>
            </div>
        </Page>
    );
}
