import {
    BrowserRouter as Router,
} from 'react-router-dom';
import {
    Routes,
    Route,
} from 'react-router';
import { CustomRouteComponent, CustomRouteSpec, CustomRouteType } from './lib/components/route';
import { CustomRoute } from './lib/components/route';
import AppRoutes from './app-routes';
import { User } from './shared';

type Props = {
    user?: User,
};

function AppNav (props: Props) {
    const getRoute = (path: string, pageName: string, type: CustomRouteType, Component: CustomRouteComponent) => {
        return (
            <Route
                key={pageName}
                path={path}
                element={
                    <CustomRoute
                        type={type}
                        user={props.user}
                        pageName={pageName}
                        Component={Component}
                    />
                }
            />
        );
    };

    return (
        <Router>
            <Routes>
                {
                    AppRoutes.map((route: CustomRouteSpec) => getRoute(route.path, route.pageName, route.type, route.component))
                }
            </Routes>
        </Router>
    );
}

export default AppNav;