import clientAuthStateHandler from './auth/state-handler';
import { API } from '../../shared';
import WSClient from './ws';
import config from '../../config';

export default class Client {
    public api: API.API;
    public ws = WSClient;

    constructor() {
        this.api = new API.API(config.api.url, clientAuthStateHandler);
    }
}
