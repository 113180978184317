import _ from 'lodash';
import { useEffect, useState } from 'react';
import Client from '../lib/client';
import { Thread } from '../shared';


export default function useThreads() {
    const [data, setData] = useState<Thread[]>([]);

    const client = new Client();
    const refetch = () => client.api.threads.getAll({ last: 30 })
        .then((result) => {
            setData(result.data);
        })
        .catch((error: Error) => console.error('error fetching threads data for hook', error));

    useEffect(() => {
        refetch();
    }, []);


    return { data, refetch };
}
