import React from 'react';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import Avatar from 'react-avatar';
import Client from '../../../lib/client';
import { User } from '../../../shared';

const signOut = async () => {
    const client = new Client();
    await client.api.auth.signOut();
}
type SideBarProps = {
    user?: User;
};
export default function ChatSidebarBottom(props: SideBarProps) {
    return (
        <div className="py-2">
            <div className="dropdown">
                <a href="#" className="d-flex align-items-center link-body-emphasis text-decoration-none text-reset dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                    <Avatar name={props.user?.name || 'user'} size='32' round={true} className=""/>
                    {/* <img src="https://github.com/mdo.png" alt="" width="32" height="32" className="rounded-circle me-2" /> */}
                    {/* <strong>{props.user?.name}</strong> */}
                </a>
                <ul className="dropdown-menu text-small">
                    <li><Link to="/account/settings" className="dropdown-item">Manage Account</Link></li>
                    <li><hr className="dropdown-divider" /></li>
                    <li><a onClick={() => signOut()} className="dropdown-item" href="#">Sign out</a></li>
                </ul>
            </div>
        </div>
    );
}