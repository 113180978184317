import { useEffect, useState } from 'react';
import Client from '../lib/client';
import { AccountConfig } from '../shared';


export default function useAccountConfig(accountId?: string) {
  const [accountConfig, setAccountConfig] = useState<AccountConfig | null>();
  useEffect(() => {
    if (!accountId) return;

    const client = new Client();
    client.api.accounts.getAccountConfigByAccountId(accountId)
      .then(setAccountConfig)
      .catch(() => setAccountConfig(null));
  }, [accountId]);

  return accountConfig;
}