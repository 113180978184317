import { DateTime } from 'luxon';

export function getDateStr(date: number, timezone?: string, format = DateTime.DATETIME_MED) {
    return DateTime.fromMillis(date, { zone: timezone }).toLocaleString(format);
}

export function getDateRelativeStr(date: number, timezone?: string) {
    return DateTime.fromMillis(date, { zone: timezone }).toRelative();
}
export function getFormattedDuration(timestamp?: number) {
    return timestamp ? new Date(timestamp).toISOString().split('T')[1].split('Z')[0] : '--';
}