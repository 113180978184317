import { Page } from '../../../components/layout/page';
import { SideBar } from '../../../components/sidebar';
import { CustomRouteProps } from '../../../lib/components/route';
import SidebarMenu from '../../../components/sidebar-menu';

export default function AccountMembers(props: CustomRouteProps) {
    return (
        <Page
            {...props}
            leftBar={(
                <SideBar {...props}>
                    <SidebarMenu pageName={props.pageName} />
                </SideBar>
            )}
            pageName={props.pageName}
        >

            <div className="container-fluid">
                <section className="mt-3 mb-2">
                    <div className="row">
                        <div className="col-12 col-md-8">
                            <div className="">
                                <p className="h4">Account members</p>
                                <p className=""></p>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

        </Page>
    );
}
