import * as _ from 'lodash';
import * as uuid from 'uuid';
import config from '../../config';
import clientAuthStateHandler from './auth/state-handler';

export interface WSMessage {
    userId: string;
    topic: string;
    payload: any;
}

export default class WSClient {
    private static ws?: WebSocket;
    private static handlers: { [key: string]: { [key: string]: (payload: any) => void } } = {};
    private static handlerTopics: { [key: string]: string } = {};

    static async init(retry = 0) {
        console.log('ws initializing...');
        try {
            if (WSClient.ws) {
                return;
            }

            const authState = clientAuthStateHandler.getAuthState();

            if (!authState) {
                throw new Error('Not authenticated');
            }

            return await WSClient.open(authState.authToken);
        } catch (error) {
            console.log('Error connecting to WS', error);
            retry > 0 && setTimeout(() => this.init(retry - 1), 1000);
        }
    }

    static async reinit(retry = 0) {
        console.log('ws re-initializing...');
        try {
            if (WSClient.ws) WSClient.ws.close();

            const auth = await clientAuthStateHandler.getAuthState();

            if (!auth) {
                throw new Error('Not authenticated');
            }

            return await WSClient.open(auth.authToken);
        } catch (error) {
            console.log('Error connecting to WS', error);
            retry > 0 && setTimeout(() => this.reinit(retry - 1), 1000);
        }
    }

    static close() {
        if (!WSClient.ws) {
            return;
        };

        WSClient.ws.close();

        WSClient.handlers = {};
        WSClient.handlerTopics = {};
        WSClient.ws = undefined;
    }

    static send(topic: string, payload: any, retry = true): void {
        if (!WSClient.ws) throw Error('ws not initialized...');
        
        const authState = clientAuthStateHandler.getAuthState();
        if (!authState) {
            throw new Error('Not authenticated');
        }

        const data: WSMessage = {
            userId: authState.userId,
            topic,
            payload,
        };
        WSClient.ws.send(JSON.stringify(data));
        console.log('ws-outgoing message', topic);
    }

    static on(topic: string, callback: (payload: WSMessage) => void, retry = true): string {
        if (!WSClient.ws) throw Error('ws not initialized...');

        if (!WSClient.handlers[topic]) {
            WSClient.handlers[topic] = {};
        }

        const handlerId: string = uuid.v4();
        WSClient.handlers[topic][handlerId] = callback;
        WSClient.handlerTopics[handlerId] = topic;
        return handlerId;
    }

    static off(handlerId: string) {
        if (!WSClient.ws) throw Error('ws not initialized...');

        const topic = WSClient.handlerTopics[handlerId];
        if (!topic) return;

        delete WSClient.handlers[topic][handlerId];
        delete WSClient.handlerTopics[handlerId];
    }

    static offTopic(topic: string) {
        if (!WSClient.ws) throw Error('ws not initialized...');

        const handlers = WSClient.handlers[topic];
        if (!handlers) return;

        _.keys(WSClient.handlers[topic])
            .map((handlerId: string) => delete WSClient.handlerTopics[handlerId]);
        delete WSClient.handlers[topic];
    }

    private static async open(token: string | null) {
        return new Promise(async (resolve, reject) => {
            // safe timeout
            let heartBeatInterval: NodeJS.Timer;
            // safe timeout
            const timeout = setTimeout(
                () => reject('ws-error opening connection. Timeout.'),
                3000,
            );
            try {
                let url = config.ws.url;
                if (!_.isNil(token)) {
                    url = `${url}?token=${token}`;
                }

                WSClient.ws = new WebSocket(url);
                WSClient.ws.addEventListener('open', () => {
                    console.log('ws-connected...');
                    heartBeatInterval = setInterval(
                        () => {
                            try {
                                WSClient.ws?.send('.');
                                console.log('heart-beat');
                            } catch (error) {
                                console.log('no-heart-beat');
                            }
                        },
                        60000,
                    );
                    clearTimeout(timeout);
                    resolve(true);
                });

                WSClient.ws.addEventListener('close', (reason: any) => {
                    console.log('ws-disconnected...');
                    setTimeout(() => WSClient.reinit(), 500);
                    heartBeatInterval && clearInterval(heartBeatInterval);
                });

                WSClient.ws.addEventListener('message', (event: MessageEvent) => {
                    try {
                        const data = JSON.parse(_.toString(event.data));
                        WSClient.handleIncomingMessage(data);
                    } catch (error) {
                        console.error('ws-error handling incoming message', error);
                    }
                });

            } catch (error: any) {
                console.error('ws-error opening connection', error.message);
                clearTimeout(timeout);
                // heartBeatInterval && clearTimeout(heartBeatInterval);
                reject(`ws-error opening connection. ${error.message}`);
            }
        });
    }

    private static handleIncomingMessage(data: any) {
        try {
            if (
                _.isNil(data)
                || !data.message
            ) throw new Error('ws-incoming message wrong payload');

            const payload = JSON.parse(data.message);
            console.log('ws-incoming message', payload, WSClient.handlers);

            const handlers = _.get(WSClient.handlers, payload.topic, {});
            _.values(handlers).forEach((handler: (payload: WSMessage) => void) => handler(payload));

            // clean if disposable
            if (payload.disposable) {
                WSClient.offTopic(payload.topic);
            }
        } catch (error) {
            console.log('ws-incoming message error', error);
        }
    }
}