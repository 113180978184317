import { useEffect } from 'react';
import WSClient, { WSMessage } from './client/ws';

export function useWSMessage(topic: string, callback: (data: WSMessage) => void, deps: any[] = []) {
    useEffect(() => {
        try {
            const handlerId = WSClient.on(topic, (message) => {
                callback(message);
            });
            return () => WSClient.off(handlerId);            
        } catch (error) {
            console.error('error subscribing to WS topic', topic, error);
        }
    }, deps);
}